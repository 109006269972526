import { tableController, translate } from 'magner';
import {
  avatarColumn, birthdayColumn, emailColumn, fioColumn, phoneColumn, positionTagsColumn, statusColumn,
} from 'features/residents/table/general';
import type { ResidentList } from 'features/residents/types/hubstr';

const residentsTable = tableController<ResidentList>({
  tableLayout: 'auto',
  scrollbarAlwaysOn: true,
  emptyText: translate('hubstr.resident.table.not_found'),
  rowLink: (row) => ({ name: 'resident', params: { id: row.id } }),
  columns: [
    avatarColumn,
    fioColumn,
    phoneColumn,
    emailColumn,
    birthdayColumn,
    positionTagsColumn,
    statusColumn,
  ],
});

export default residentsTable;
