<template>
  <div>
    <h4>ФИО:</h4>
    <p>{{ s.fio }}</p>

    <el-divider />

    <h4>E-mail:</h4>
    <p>{{ s.email }}</p>

    <el-divider />

    <h4>Описание нарушения:</h4>
    <p>{{ s.text }}</p>

    <el-divider />

    <h4>Дата создания:</h4>
    <p>{{ s.formattedDate }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { IFeedBack } from 'features/profile/feedback/types';
import { formatDate } from '~/utils/format-date';

export default defineComponent({
  name: 'FeedbackDetail',
  props: {
    feedback: {
      type: Object as PropType<IFeedBack>,
      required: true,
    },
  },
  emits: ['success'],
  setup (props) {
    return {
      s: {
        ...props.feedback,
        formattedDate: formatDate(props.feedback.createdAt),
      },
    };
  },
});
</script>
