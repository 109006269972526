import { translate, filtersFormController } from 'magner';
import type { Resident } from 'features/residents/types/hubstr';
import { clubRoleRead } from 'features/settings/club-roles/requests/hubstr';
import { residentStatusList } from 'features/residents/requests/hubstr';

const residentsFilters = filtersFormController<Resident>({
  actions: [],

  saveToLocalStorage: true,

  submitEvent: 'input',

  pagination: {
    items: 25,
    page: 1,
  },
  filtersInSeparatePanel: true,
  filtersData: {
    firstName: '',
    phone: '',
    positionTags: '',
    status: '',
  },
  sort: {},

  layout: [
    {
      type: 'input',
      name: 'firstName',
      props: {
        placeholder: translate('hubstr.resident.filters.name'),
        inputDelay: 250,
      },
    },
    {
      type: 'input',
      name: 'phone',
      props: {
        placeholder: translate('hubstr.resident.filters.phone'),
        inputDelay: 250,
        mask: {
          mask: '+7##########',
        },
      },
    },
    {
      type: 'select',
      name: 'positionTags',
      dataType: 'array',
      options: [],
      props: {
        multiple: false,
        filterable: true,
        remote: true,
        valueKey: 'id',
        labelKey: 'name',
        remoteMethod: clubRoleRead,
        placeholder: translate('hubstr.resident.filters.positionTags'),
      },
    },
    {
      type: 'select',
      name: 'status',
      dataType: 'array',
      options: [],
      props: {
        multiple: true,
        filterable: true,
        remote: true,
        valueKey: 'code',
        labelKey: 'title',
        remoteMethod: residentStatusList,
        placeholder: translate('hubstr.resident.filters.status'),
      },
    },
  ],
});

export default residentsFilters;
