import { Resident, ResidentCreate } from 'features/residents/types/hubstr';
import { v4 as uuidv4 } from 'uuid';
import { fileUpload } from 'features/common/file-request';
import { residentUpdateStatus } from 'features/residents/requests/hubstr';
import { request } from '~/utils/request';

const BASE_URL = '/api/admin/user';

const createNestedKeys = [
  'badge',
  'expertise',
  'provideResources',
  'achievements',
  'yearTarget',
  'lookResources',
  'request',
  'industries',
  'businessStartYear',
  'turnoverPerYear',
  'numberOfEmployees',
  'factsAboutMe',
  'interests',
  'education', 'familyStatus', 'children', 'videoPresentation', 'personalWww', 'formGroup', 'atlas',
  'department'];

export const residentCreate = request.card<Resident, ResidentCreate>(async ({
  api, parseError, data, router,
}) => {
  const id = uuidv4();

  const body: any = { id, additions: {} };
  Object.entries(data.data).forEach(([key, val]) => {
    if (createNestedKeys.includes(key)) {
      body.additions[key] = val;
    } else {
      body[key] = val;
    }
  });
  if (data.data.dateOfEntry) {
    const entry = new Date(data.data.dateOfEntry);
    body.dateOfEntry = new Date(entry.getTime() - entry.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.birthday) {
    const bd = new Date(data.data.birthday);
    body.birthday = new Date(bd.getTime() - bd.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.roles) body.roles = [data.data.roles] as unknown as ResidentCreate['roles'];
  if (data.data.city) body.additions.city = data.data.city;
  if (data.data.interests) body.additions.interests = data.data.interests;
  if (data.data.industries) body.additions.industries = data.data.industries;
  if (typeof data.data.sex === 'string') body.sex = null;
  if (!data.data.department) body.department = null;

  if (data.data.avatar) {
    const fileUploadRes = await fileUpload(
      id as string,
      (data.data.avatar as File[])[0],
      'user',
      'user_avatar',
      'userAvatar',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { avatar: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.avatar = fileUploadRes.data?.id;
  } else {
    body.avatar = null;
  }

  const res = await api.post<{ user: Resident }>(`${BASE_URL}/create`, body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  await router.push({ name: 'resident', params: { id: res.data?.user.id } });
  return { data: res.data?.user };
});

export const residentUpdate = request.card<Resident, ResidentCreate>(async ({ api, parseError, data }) => {
  const body: any = { id: data.id, additions: {} };
  Object.entries(data.data).forEach(([key, val]) => {
    if (createNestedKeys.includes(key)) {
      body.additions[key] = val;
    } else {
      body[key] = val;
    }
  });
  if (data.data.dateOfEntry) {
    const entry = new Date(data.data.dateOfEntry);
    body.dateOfEntry = new Date(entry.getTime() - entry.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.birthday) {
    const bd = new Date(data.data.birthday);
    body.birthday = new Date(bd.getTime() - bd.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.roles) body.roles = [data.data.roles] as unknown as ResidentCreate['roles'];
  if (data.data.city) body.additions.city = data.data.city;
  if (data.data.interests) body.additions.interests = data.data.interests;
  if (data.data.industries) body.additions.industries = data.data.industries;
  if (typeof data.data.sex === 'string') body.sex = null;

  if (data.data.avatar) {
    const fileUploadRes = await fileUpload(
      data.id as string,
      (data.data.avatar as File[])[0],
      'user',
      'user_avatar',
      'userAvatar',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { avatar: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.avatar = fileUploadRes.data?.id;
  } else {
    body.avatar = null;
  }

  const res = await api.patch<{ user: Resident }>(`${BASE_URL}/update`, body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  // Если обновился статус
  if (data.data.status) {
    await residentUpdateStatus(data);
  }

  return { data: res.data?.user };
});
