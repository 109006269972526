import { tableController, translate } from 'magner';
import {
  avatarColumn, birthdayColumn, emailColumn, fioColumn, phoneColumn, statusColumn,
} from 'features/residents/table/general';
import type { ResidentList } from 'features/residents/types/hubstr';

const residentsTable = tableController<ResidentList>({
  tableLayout: 'auto',
  emptyText: translate('hubstr.resident.table.not_found'),
  rowLink: (row) => ({ name: 'resident', params: { id: row.id } }),
  columns: [
    avatarColumn,
    fioColumn,
    phoneColumn,
    emailColumn,
    birthdayColumn,
    {
      prop: 'positionTags',
      label: translate('hubstr.resident.table.tags'),
      view: {
        type: 'tags',
        formatter: (cellValue) => (cellValue as ResidentList['positionTags']).map((tag) => tag.name),
      },
    },
    statusColumn,
  ],
});

export default residentsTable;
